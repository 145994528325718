window.onscroll = function() {scrollFunction()};

function scrollFunction() {
    let navbar = document.querySelector("#navbar");
    if(window.scrollY < 1000  && window.scrollY > 0 && document.querySelector("video")){
        navbar.classList.remove("bg-light");
    }
    else{
        navbar.classList.add("bg-light");
    }
}

function toggleNav(){
    const navNavbar = document.querySelector('#nav-content');
    const navBurger = document.querySelector('.nav-navbar-toggler');
    const navBurger_icon = document.querySelector('.nav-navbar-toggler-icon');
    navBurger.addEventListener('click', () =>{
        navNavbar.classList.toggle('open');
        navBurger_icon.classList.toggle('nav-navbar-toggler-icon-click')
    });
    new ResizeObserver(entries => {
        if(entries[0].contentRect.width <= 950){
            navNavbar.style.transition = "transform .3s ease-out";
        }
        else{
            navNavbar.style.transition = "none";
        }
    }).observe(document.body)
}
toggleNav();
